import { randomUUID } from './interface/Common'

export default class SpecialPackage {
  public type: string | null = null

  public media_package_id: string | null = null

  public id: any = null

  public name: any = null

  public duplicated: boolean = false

  constructor() {
    this.id = randomUUID()
  }
}
