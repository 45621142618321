
import { Vue, Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPackage from '@/models/MediaPackage'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import RowToggle from '@/components/RowToggle/RowToggle.vue'
import AdunitWrapper from '@/components/AdunitSelector/AdunitWrapper.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { currencyMask } from '@/models/interface/Masks'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import SpecialPackageWidget from '@/components/SpecialPackage/SpecialPackageWidget.vue'
import WebMessage from '@/models/WebMessage'
import DistributionGoalGroup from './components/DistributionGoalGroup.vue'
import options from './options'
import fields from './fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    RowToggle,
    AdunitWrapper,
    FormInput,
    CheckboxInput,
    DistributionGoalGroup,
    SpecialPackageWidget,
  },
})
export default class MediaPackageHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public media_package: MediaPackage = new MediaPackage()

  public action: string = 'delete'

  public modal: boolean = false

  public records: number = 0

  public loading: boolean = false

  public ready = false

  public query: string[] = []

  public selected: string[] = []

  public map_mode: string = 'dma'

  public logos: string[] = []

  public fieldFilters: any = {}

  public bulk_modal: boolean = false

  public selected_to_update: string[] = []

  public overwrites: any = {
    ad_units: true,
    special_packages: true,
    distribution_goals: true,
  }

  public get masks() {
    return {
      currency_mask: currencyMask,
    }
  }

  public get options() {
    return options
  }

  public slides: string[] = [
    // 'cover', // On by default
    'overview',
    'details',
    'distribution',
    'placements',
    // 'outro', // On by default
  ]

  public appendix: boolean = false

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return MediaPackage.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createMediaPackage() {
    this.$router.push('/app/sales/media_package')
  }

  public editMediaPackage(media_package: MediaPackage) {
    this.$router.push(`/app/sales/media_package/${media_package.id}`)
  }

  public cloneMediaPackage(media_package: MediaPackage) {
    this.$router.push(`/app/sales/media_package?from=${media_package.id}`)
  }

  public confirmDelete(media_package: MediaPackage) {
    this.media_package = media_package
    this.action = 'delete'
    this.modal = true
  }

  public confirmActivate(media_package: MediaPackage) {
    this.media_package = media_package
    this.action = 'activate'
    this.modal = true
  }

  public confirmDeactivate(media_package: MediaPackage) {
    this.media_package = media_package
    this.action = 'deactivate'
    this.modal = true
  }

  public deleteMediaPackage() {
    if (this.media_package.id) {
      this.media_package.delete().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public activateMediaPackage() {
    if (this.media_package.id) {
      this.media_package.activate().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public deactivateMediaPackage() {
    if (this.media_package.id) {
      this.media_package.deactivate().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  public modalConfirm() {
    switch (this.action) {
      case 'delete':
        this.deleteMediaPackage()
        break
      case 'activate':
        this.activateMediaPackage()
        break
      case 'deactivate':
        this.deactivateMediaPackage()
        break
    }
  }

  public get modal_action_button() {
    switch (this.action) {
      case 'delete':
        return 'Delete'
      case 'activate':
        return 'Activate'
      case 'deactivate':
        return 'Deactivate'
      case 'send':
        return 'Send'
      case 'donwloadReport':
      case 'export':
        return 'Download'
      default:
        return 'Save'
    }
  }

  public get modal_title() {
    switch (this.action) {
      case 'delete':
        return 'Delete Media Package'
      case 'activate':
        return 'Activate Media Package'
      case 'deactivate':
        return 'Deactivate Media Package'
      case 'send':
        return 'Send IO'
      case 'donwloadReport':
        return 'Download Delivery Report'
      case 'export':
        return 'Export IOs'
      default:
        return 'Save'
    }
  }

  public mounted() {
    this.fields = fields
    this.loadFilters()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_package',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('media_package').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_package',
      data: null,
    })
  }

  public isEnabled(name: string) {
    return this.selected_to_update.includes(name)
  }

  public useRuleWhenSelected(key: string, rules: any) {
    if (this.isEnabled(key)) {
      return rules
    }
    return ''
  }

  public cancelBatchUpdate() {
    this.media_package = new MediaPackage()
    this.bulk_modal = false
    this.selected = []
    this.selected_to_update = []
    this.overwrites = {
      ad_units: true,
      special_packages: true,
      distribution_goals: true,
    }
  }

  public batchUpdate() {
    this.loading = true
    // get selecteds
    let payload: any = {}
    this.selected_to_update.forEach((prop: string) => {
      let key = prop.replaceAll('media_package.', '')
      if (key === 'rate') {
        payload[key] = this.media_package[key]
        if (this.media_package.overwrite_rate) {
          payload.overwrite_rate = true
        }
      } else if (key === 'ad_unit') {
        payload.adunit_ids = this.media_package.adunit_ids
        payload.exclude_adunit_ids = this.media_package.exclude_adunit_ids
      } else {
        // @ts-ignore
        payload[key] = this.media_package[key]
      }
    })

    this.media_package
      .batchUpdate(this.selected, payload, this.overwrites)
      .then(() => {
        this.cancelBatchUpdate()
        this.loading = false
        WebMessage.success('Media Packages updated successfully')
        setTimeout(() => {
          this.dataTable.refresh()
        }, 200)
      })
      .catch(() => {
        this.cancelBatchUpdate()

        this.loading = false
        setTimeout(() => {
          this.dataTable.refresh()
        }, 200)
      })
  }
}
