
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SpecialPackage from '@/models/SpecialPackage'
import SelectPicker from '../SelectPicker/SelectPicker.vue'
import MediaPackagePicker from '../MediaPackagePicker/MediaPackagePicker.vue'
import IconAction from '../IconAction/IconAction.vue'

@Component({
  components: {
    SelectPicker,
    MediaPackagePicker,
    IconAction,
  },
})
export default class SpecialPackageWidget extends ViewModel {
  @Prop({ default: 'special package' })
  public name!: string

  @Prop({ default: null })
  public rules: any

  @Prop()
  public value!: SpecialPackage[]

  @Prop({ default: true })
  public local_label!: boolean

  @Prop({ default: false })
  public disabled!: any

  @Prop({ default: false })
  public hideButton!: any

  @Prop({ default: false })
  public isLoading!: any

  public checking: boolean = false

  public ready: boolean = false

  public get local_value() {
    return this.value
  }

  public set local_value(value: SpecialPackage[]) {
    this.$emit('input', value)
  }

  public get duplicated_error() {
    if (!this.local_value || !this.local_value.length) return false
    return this.local_value.some(v => v.duplicated)
  }

  // Trade or DoubleVerify
  public package_types = [
    {
      name: 'Trade',
      value: 'trade',
    },
    {
      name: 'DoubleVerify 15s',
      value: 'dv_15',
    },
    {
      name: 'DoubleVerify 30s',
      value: 'dv_30',
    },
    {
      name: '60s',
      value: '60s',
    },
    {
      name: '75s',
      value: '75s',
    },
    {
      name: '90s',
      value: '90s',
    },
  ]

  public get incompleteOption() {
    if (!this.local_value || !this.local_value.length) return false
    return this.local_value.some(v => !v.type || !v.media_package_id)
  }

  public addPackage() {
    if (
      this.disabled
      || this.duplicated_error
      || this.incompleteOption
      || this.isLoading
      || this.loading
    ) {
      return
    }
    this.local_value.push(new SpecialPackage())

    this.registerWatchers()
  }

  public mounted() {
    this.registerWatchers()

    setTimeout(() => {
      this.ready = true
    }, 500)
  }

  public removePackage(index: number) {
    this.local_value.splice(index, 1)
    this.checkRemainingRepeats()
  }

  public registerWatchers() {
    this.local_value.forEach((pkg: any, index) => {
      if (pkg._watcher) {
        return
      }

      pkg._watcher = this.$watch(
        () => pkg.type,
        val => {
          if (pkg.media_package_id) {
            this.checkDuplicateType(pkg)
          }
        },
      )

      pkg._watcher = this.$watch(
        () => pkg.media_package_id,
        val => {
          this.checkDuplicateType(pkg)
        },
      )
    })
  }

  public checkDuplicateType(pkg: any) {
    if (this.checking) return
    this.checking = true
    return new Promise(resolve => {
      let repeated = this.local_value.filter(
        v =>
          v.id !== pkg.id && v.type === pkg.type && v.media_package_id === pkg.media_package_id,
      )

      if (repeated.length > 0) {
        pkg.duplicated = true
        repeated.forEach(v => {
          v.duplicated = true
        })
      } else {
        pkg.duplicated = false

        this.checkRemainingRepeats()
      }

      setTimeout(() => {
        resolve(true)
        this.checking = false
      }, 300)
    })
  }

  public checkRemainingRepeats() {
    let still_repeated = this.local_value.filter(v => v.duplicated)

    still_repeated.forEach(repeated => {
      let r_idx = this.local_value.findIndex(v => v.id === repeated.id)

      let r = this.local_value.filter(
        v =>
          v.id !== repeated.id
          && v.type === repeated.type
          && v.media_package_id === repeated.media_package_id,
      )

      if (!r.length) {
        this.local_value[r_idx].duplicated = false
      }
    })
  }

  public emitErrors(errors: any) {
    this.$emit('errors', errors)
    return ''
  }
}
