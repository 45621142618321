
import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPackageForm from './MediaPackageForm.vue'

@Component({
  components: {
    MediaPackageForm,
  },
})
export default class MediaPackageEdit extends ViewModel {
  @Prop()
  public id!: string

  private get title() {
    return this.id ? 'Edit Package' : 'Create Package'
  }
}
