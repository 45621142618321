import { StatusOptions } from '@/models/interface/Common'
import { currencyMask } from '@/models/interface/Masks'
import MediaPackage from '@/models/MediaPackage'

export default {
  name: 'Media Package',
  sections: [
    {
      name: 'Package Information',
      fields: [
        {
          name: 'Package Name',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
          description: 'Enter package name.',
        },
        {
          name: 'Program Name',
          type: 'text',
          target: 'program_name',
          rules: 'max:255',
          description: 'Enter package name.',
        },
        {
          name: 'Status',
          type: 'picker',
          target: 'status',
          rules: 'required',
          description: "If inactive it can't be linked with new Media Plans.",
          options: StatusOptions,
        },
        /* {
          name: 'Package Branding Image',
          type: 'image',
          target: 'image',
          file: 'file',
          description:
            '(Optional) Image can be used for package branding on building IOs & Media Plans.',
        }, */
        {
          name: 'Package Description',
          type: 'area',
          target: 'description',
          description: 'Enter package description.',
        },
        {
          name: 'Offline Product?',
          target: 'is_offline',
          type: 'checkbox',
          description: 'Use it for including CCL line items, brand study and others.',
        },
        {
          name: 'Ad Units',
          type: 'adunit_selector',
          target: 'adunit_ids',
          rules: 'required',
          componentClass: {
            wrapper: 'my-4',
          },
          errorMsg: true,
          visible: (media_package: MediaPackage) => !media_package.is_offline,
        },
        // {
        //   name: 'Exclude Ad Units',
        //   label: 'Exclude Ad Units',
        //   type: 'adunit_exclude',
        //   target: 'exclude_adunit_ids',
        //   rules: 'required',
        //   visible: (media_package: MediaPackage) => !media_package.is_offline,
        // },
        {
          name: 'Overwrite Price',
          target: 'overwrite_rate',
          type: 'checkbox',
          description: 'If yes, allows you to set a price for the package.',
        },
        {
          name: 'Package Price',
          target: 'rate',
          type: 'number',
          mask: currencyMask,
          description: 'Default package price',
        },
      ],
    },
  ],
}
